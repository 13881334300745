import { useState } from "react";
import { Stack, Box } from "@mui/material";
import { useLottie } from "lottie-react";
import coins from "./coins.json";

const Bundle = ({ onClick, price, img }) => {
  const options = {
    animationData: coins,
    loop: false,
  };

  const { View, stop, goToAndPlay } = useLottie(options);
  const [showAnimation, setShowAnimation] = useState(false);

  return (
    <Stack
      onClick={onClick}
      style={{
        width: 200,
        height: 300,
        borderRadius: 16,
        color: "#fff",
        overflow: "hidden",
        position: "relative",
      }}
      justifyContent='center'
      alignItems='center'
      className='bundle'
      onMouseEnter={() => {
        goToAndPlay(0, true);
        setShowAnimation(true);
      }}
      onMouseLeave={() => {
        setShowAnimation(false);
        stop();
      }}
    >
      <Box position='absolute' zIndex={200}>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "3em",
            textShadow: "rgb(0, 0, 0) 0px 2px 0px",
          }}
        >
          {price}
        </span>
        <Box mt={3}></Box>
        <img style={{ width: 100 }} src={img} alt='' />
      </Box>
      <Box
        position='absolute'
        zIndex={100}
        style={{
          transition: "0.18s",
          opacity: showAnimation ? 0.5 : 0,
          width: 350,
        }}
      >
        {View}
      </Box>
    </Stack>
  );
};

export default Bundle;
