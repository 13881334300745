import { useEffect, useState } from "react";
import "./App.css";
import { Stack } from "@mui/material";
import Bundle from "./Bundle";

Notification.requestPermission();

const playerId = "player" + Math.floor(Math.random() * 1000) + 1;

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [iframeShowing, setIframeShowing] = useState(false);

  const url = new URL(window.location.href);
  const urlParams = url.searchParams;
  const localAddress = urlParams.get("local_address");

  return (
    <div className='App'>
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: 0,
          height: 0,
        }}
        src={`${
          process.env.REACT_APP_CHECKOUT_URL
        }?bootLocation=${window.location.origin
          .replace("https://", "")
          .replace("localhost:3001", process.env.REACT_APP_BOOT)}${
          localAddress !== null ? `&local_address=${localAddress}` : ""
        }&preLoad=true`}
        title='none'
      />
      <Stack
        direction='column'
        justifyContent='center'
        alignItems='center'
        pt={8}
      >
        <img
          src='https://i.imgur.com/n7nrDr1.png'
          style={{ width: 300 }}
          alt='logo'
        />
        <Stack gap={8} direction='row' pt={16}>
          <Bundle
            onClick={() => {
              setShowPopup(true);
              setCurrentPrice(100 * 100);
              setIframeShowing(false);
            }}
            img={"https://cdn-icons-png.flaticon.com/512/3305/3305053.png"}
            price={"$100"}
          />
          <Bundle
            onClick={() => {
              setShowPopup(true);
              setCurrentPrice(3 * 100);
              setIframeShowing(false);
            }}
            img={"https://cdn-icons-png.flaticon.com/512/3305/3305221.png"}
            price={"$3"}
          />
          <Bundle
            onClick={() => {
              setShowPopup(true);
              setCurrentPrice(500 * 100);
              setIframeShowing(false);
            }}
            img={"https://cdn-icons-png.flaticon.com/512/3305/3305165.png"}
            price={"$500"}
          />
        </Stack>
      </Stack>
      {showPopup && (
        <Stack
          position='absolute'
          height='100vh'
          width='100vw'
          justifyContent='center'
          alignItems='center'
          zIndex={250}
          style={{
            top: 0,
            left: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <Stack
            style={{
              width: 450,
              height: 500,
              borderRadius: "30px",
              border: "4px solid rgb(255, 196, 57)",
              background: `radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
              radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)`,
              boxShadow: "0px 0px 30px 0px #fedb37",
            }}
            justifyContent='center'
            alignItems='center'
            position='relative'
          >
            <button
              style={{
                top: "16px",
                right: "16px",
                color: "white",
                background: "transparent",
                position: "absolute",
                border: 0,
                fontSize: "24px",
                fontWeight: "bold",
                cursor: "pointer",
                zIndex: 600,
              }}
              onClick={() => setShowPopup(false)}
            >
              X
            </button>
            {!iframeShowing && (
              <span className='loader' style={{ marginTop: -22 }}></span>
              // <h2 style={{ color: "white" }}>
              //   Loading payment options
              //   <br />
              //   <span style={{ fontSize: "16px" }}>A few more seconds...</span>
              // </h2>
            )}
            <iframe
              onLoad={() => setIframeShowing(true)}
              src={`${process.env.REACT_APP_CHECKOUT_URL}/?playerId=${playerId}&price=${currentPrice}&sessionMetadata={"extraDetails": "something"}&bootLocation=${process.env.REACT_APP_BOOT}`}
              title='checkout'
              style={{
                position: iframeShowing ? "relative" : "absolute",
                zIndex: 300,
                width: "750px",
                height: "530px",
                border: "0px",
                marginTop: -32,
                opacity: iframeShowing ? 1 : 0,
              }}
            ></iframe>
          </Stack>
        </Stack>
      )}
    </div>
  );
}

export default App;
